
.main-panel {
    margin: 25px;
}

.top-panel {
    overflow: hidden;
}

.top-title {
    background-color: #323237;
    color: #FFFFFF;
    font-family: SegoeUI, Segoe UI;
    height: 140px;
    width: 2500px;
    display: table-cell;
    vertical-align: middle;
}

.top-title-inner {
    margin: 8px;
}

.top-title-inner-sub {
    margin: 8px;
    font-size: 20px;
}

.search-container {
    margin-top: 20px;
}

.categorymenu-item {
    background-color: #505055;
    color: #FFF;
    text-align: left;
    border: 0;
    font-size: 14px;
    font-family: SegoeUI, Segoe UI;
    cursor: pointer;
}

.selectedcategorymenu-item {
    background-color: #0078D4;
    color: #FFF;
}

.layout-parent-div {
    float: left;
    width: 100%
}

.layout-div-left {
    float: left;
    width: 20%
}

.layout-div-right {
    float: right;
    width: 80%
}

.layout-div-center {
    width: 100%
}

.div-hide {
    height: 0px;
    visibility: hidden;
}

.div-show {
    visibility: visible;
}

.no-results {
    font-family: SegoeUI, Segoe UI;
    color: #969696;
    font-size: 24px;
    font-weight: 200;
    text-align: center;
}

.services-table {
}

.service-layout {
    margin-left: 15px;
    margin-bottom: 15px;
    padding: 10px;
    float: left;
    width: 30%;
    height: 100px;
    border: 1px solid #DFDFDF;
    font: normal bold 14px Segoe UI;
    color: #4C4C51;
    cursor: pointer;
    box-shadow: inset 0 1px 1px rgba(223,223,223,1), 0 3px 3px rgba(223,223,223,1);
    position: relative;
}

.service-layout:hover {
        border: 1px solid #0078D4;
    }

.service-search-box {
    width: 98%;
    border: none;
    outline: none;
}

.service-search-container {
    border: 1px solid #969696;
    outline: thin;
    height: 36px;
    margin: 0 0 8px 0;
    padding: 6px;
    width: 100%;
}

.service-notes {
    width: 90%;
    float: left;
    font: normal 11px Segoe UI;
}

.service-content-left {
    width: 10%;
    float: left;
}

.service-content-right {
    width: 90%;
    float: right;
}

.service-description {
    width: 100%;
    font: normal 14px Segoe UI;
}

.clear-search {
    background: 0 0;
    float: right;
    border: none;
    outline: none;
}

.tier-head {
    width: 100%;
    height: 35px;
    border-bottom: 1px solid #323237;
}

.estimation-head {
    height: 35px;
    border-bottom: 1px solid #323237;
    margin-left: 15px;
    margin-right: 15px;
    width: 95%;
}

.estimation-layout {
    width: 100%;
    height: 80px;
}

.estimation-head-ec-arrow {
    float: left;
    width: 2%;
    cursor: pointer;
}

.tier-head-title-left {
    float: left;
    font-family: SegoeUI, Segoe UI;
    color: #000000;
    font-size: 16px;
    font-weight: bold;
    width: 10%;
    padding: 2px;
}

.tier-head-region {
    float: left;
    font-family: SegoeUI, Segoe UI;
    color: #000000;
    font-size: 14px;
    width: 80%;
    padding: 2px;
}

.tier-head-delete {
    float: right;
    width: 8%;
    padding: 2px;
}

.estimation-head-title {
    float: left;
    font-family: SegoeUI, Segoe UI;
    color: #000000;
    font-size: 14px;
    width: 29%;
    padding: 2px;
}

.estimation-head-delete {
    float: right;
    width: 8%;
    text-align: right;
    cursor: pointer;
}

.estimation-service {
    display: inline-block;
    vertical-align: middle;
    width: 100%;
}

.estimation-service-icon {
    width: 2%;
    float: left;
    vertical-align: middle;
    margin-left: 15px;
}

.estimation-service-name {
    width: 70%;
    float: left;
    font-size: 18px;
    font-weight: 600;
    color: #323237;
    line-height: 1.5;
    padding: 0 21px;
    margin-left: 1px;
    vertical-align: middle;
}

.estimation-sla {
    float: right;
    font-size: 18px;
    font-weight: 600;
    color: #323237;
    line-height: 1.5;
    margin-right: 40px;
    vertical-align: middle;
}

.estimation-spacer {
    height: 40px;
}

div {
    
}

.down-arrow {
    width: 17px;
    height: 17px;
    border: 0;
    background: url('/images/downarrow.png') no-repeat;
    cursor: pointer;
}

.up-arrow {
    width: 17px;
    height: 17px;
    border: 0;
    background: url('/images/uparrow.png') no-repeat;
    cursor: pointer;
}

.estimation-collapse-all {
    float: right;
    display: table-cell;
    width: 40px;
    height: 40px;
    background: url('/images/collapseall.png') no-repeat;
    cursor: pointer;
}

.estimation-expand-all {
    float: right;
    display: table-cell;
    width: 40px;
    height: 40px;
    background: url('/images/expandall.png') no-repeat;
    cursor: pointer;
}

.estimation-delete-all {
    float: right;
    display: table-cell;
    width: 40px;
    height: 40px;
    background: url('/images/delallsla.png') no-repeat;
    cursor: pointer;
}

.estimation-collapse-all:hover {
    background: url('/images/collapseall-over.png') no-repeat;
    cursor: pointer;
}

.estimation-expand-all:hover {
    background: url('/images/expandall-over.png') no-repeat;
    cursor: pointer;
}

.estimation-delete-all:hover {
    background: url('/images/deleteallsla-over.png') no-repeat;
    cursor: pointer;
}

.estimation-totals-panel {
    border-top: 2px solid #323237;
    height: 100px;
}

.estimation-total-label {
    font-family: SegoeUI, Segoe UI;
    color: #2E2E33;
    font-size: 20px;
    font-weight: 400;
    text-align: right;
}

.estimation-total-label-underline {
    font-family: SegoeUI, Segoe UI;
    text-decoration: underline;
    color: #2E2E33;
    font-size: 20px;
    font-weight: 600;
    text-align: right;
}

.tier-estimation-total-label {
    font-family: SegoeUI, Segoe UI;
    color: #2E2E33;
    font-size: 17px;
    font-weight: 400;
    text-align: right;
}

.estimation-notes {
    text-align: left;
    margin-left: 10px;
    padding: 10px;
}

.tier-option-div {
    font-size: 14px;
    font-family: SegoeUI, Segoe UI;
    float: left;
    width: 320px;
    height: 25px;
}

.tier-label {
    font-size: 14px;
    font-family: SegoeUI, Segoe UI;
    float: left;
    color: #323237;
    font-weight: bold;
    display: table-cell;
    vertical-align: middle;
    width: 40px;
    height: 34px;
}

.tier-container {
    height: 30px;
}

.regions-option-hidden {
    height: 0px;
    visibility: hidden;
}

.regions-option {
    width: 80px;
    color: #0078D4;
}

.new-tier-box {
    border-style: none;
}

.new-tier {
    width: 162px;
    float: left;
    color: #6C757D;
    border-color: #6C757D;
    border: 1px;
    border-style: solid;
}

.addservice-alert-hide {
    height: 0px;
    visibility: hidden;
}

.addservice-alert-visible {
    height: 70px;
    width: 160px;
    visibility: visible;
    font-size: 14px;
    font-family: SegoeUI, Segoe UI;
    background-color: #FFF;
    color: #323237;
    border: 1px solid #323237;
    z-index: 22;
    position: relative;
    top: 100px;
    padding-left: 10px;
    margin: 0 auto;
}

.service-hl {
    font-size: 10px;
    font-weight: 400;
    position: absolute;
    bottom: 3px;
    right: 3px;
}

.tier-hide {
    display: none;
}

.tier-show {
    display: block;
}

.tiers-panel-hide {
    display: none;
}

.tiers-panel-show {
    display: block;
}

.region-div-left {
    float: left;
    width: 30%
}

.region-div-right {
    float: right;
    width: 70%
}

.legal-site-spacer {
    height: 40px;
}

.service-head {
    float: right;
    height: 40px;
    cursor: pointer;
}

.dropdown {
    width: 300px;
    float: left;
    height: 25px;
}

.dropdown-container {
    width: 527px;
}

.add-tier {
    width: 25px;
    float: right;
    border: none;
    background-color: #0078D4;
    color: #FFFFFF;
}

.dropdown-header {
    width: 295px;
    height: 25px;
    border: 1px;
    border-style: solid;
    padding: 0;
    cursor: pointer;
}

.dropdown-content {
    width: 280px;
    display: none;
    position: absolute;
    z-index: 1;
}

.delete-tier {
    cursor: pointer;
    width: 15px;
    height: 15px;
    float: right;
    background: url('/images/delete.png') no-repeat;
    margin-top: 4px;
    margin-right: 4px;
    background-color: transparent;
}

.dropdown-item {
    padding: 0;
}

.dropdown-item-text:hover {
    color: #0078D4;
}

.dropdown-item-text {
    cursor: pointer;
    font-family: SegoeUI, Segoe UI;
    color: #969696;
    width: 270px;
    float: left;
    padding: 0;
    margin-left: 3px;
    margin-top: 2px;
    margin-bottom: 2px;
    background-color: transparent;
}


.dropdown-content-show {
    width: 295px;
    display: block;
    position: absolute;
    z-index: 1;
    background-color: #FFFFFF;
    border: 1px;
    border-style: solid;
}

.dropdown-header-text {
    float: left;
    height: 25px;
    font-size: 14px;
    margin-left: 4px;
}

.dropdown-header-arrow {
    width: 17px;
    height: 25px;
    margin-top: 4px;
    border: 0;
    background: url('/images/downarrow.png') no-repeat;
    cursor: pointer;
    float: right;
}

a {
    font-size: 12px;
}

html {
    font-size: 14px;
}
